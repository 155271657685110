<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="900px"
      top="10%"
      :title="titleMsg"
      :close-on-click-modal="false"
      class="access-allocation-dialog"
      @close="closeEmail"
    >
      <div>
        <el-tabs v-model="activeName" :before-leave="changeTabs">
          <el-tab-pane :label="$t('dc:操作权限')" name="1">
            <div class="groupSelect">
              <div>
                <label>{{ $t('dc:产品名称') }}</label>
                <el-select
                  filterable
                  :clearable="true"
                  :placeholder="$t('dc:选择产品')"
                  v-model="projectId"
                  @change="selectProduct"
                >
                  <el-option
                    v-for="item in productList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <label>{{ $t('dc:模块名称') }}</label>
                <!-- <el-select
                  filterable
                  :disabled="projectId == ''"
                  :clearable="true"
                  :placeholder="$t('dc:选择模块')"
                  v-model="moduleId"
                  @change="selectModule"
                >
                  <el-option
                    v-for="item in moduleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select> -->
                <el-input
                  :clearable="true"
                  filterable
                  :disabled="projectId == ''"
                  v-model="keyword"
                  :placeholder="$t('dc:请输入')"
                  @keyup.enter.native.prevent="selectModule"
                  @clear="selectModule"
                ></el-input>
              </div>
            </div>
            <PocTable
              v-loading="loading"
              :data="tableData"
              :current-page.sync="pageConfig.pageNum"
              :total="pageConfig.total"
              :border="false"
              height="400"
              hidepage
              @size-change="pageSizeChange"
              @page-current-change="pageChange"
            >
              <el-table-column
                v-for="item in columnConfig"
                :key="item.key"
                :prop="item.chlidren ? null : item.key"
                :label="item.name"
                :fixed="item.fixed"
                :sorter="item.sortable"
                :width="item.key === 'no' ? 60 : null"
                :min-width="item.width"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="item.key === 'projectName'">
                    <span class="projectName">{{ scope.row[item.key] }}</span>
                  </span>
                  <span v-else-if="item.key === 'moudule'">
                    <el-checkbox
                      :width="35"
                      v-model="scope.row.selected"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      @change="changeShowAccessList(scope.row.selected, scope.$index)"
                      >{{ scope.row.moduleName }}</el-checkbox
                    >
                  </span>
                  <span v-else-if="item.key === 'accessList'">
                    <el-checkbox-group v-model="scope.row.accessList" class="checkBox">
                      <el-checkbox
                        :disabled="!scope.row.selected"
                        v-for="(items, index) in scope.row.accessNewlist"
                        :key="index"
                        :label="items.accessId"
                        >{{ items.accessName }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </span>

                  <span v-else>{{ scope.row[item.key] }}</span>
                </template>
              </el-table-column>
            </PocTable>
          </el-tab-pane>
          <el-tab-pane :label="$t('dc:数据权限')" name="2" class="elTabtwo">
            <el-form
              label-position="right"
              label-width="94px"
              :inline="false"
              ref="formData"
              :model="formData"
              :rules="rules"
              v-show="dimensionType === 0"
            >
              <el-form-item :label="$t('dc:纬度')" class required>
                <el-select
                  filterable
                  ref="dimension1"
                  style="width: 424px"
                  :placeholder="$t('dc:请选择')"
                  v-model="dimensionType"
                  @change="selectDimemsion"
                >
                  <el-option
                    v-for="item in dimensionOptionsp"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                    @click.native="selectDimension(item)"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('dc:客户')" class required>
                <el-select
                  v-model="formData.clientId"
                  @change="changClient"
                  filterable
                  :placeholder="$t('dc:请选择')"
                  :popper-class="!noHistorical ? 'clientBox NoHistorical' : 'clientBox'"
                  style="width: 424px"
                >
                  <el-option-group
                    v-for="(group, index) in options"
                    :key="index + '-id'"
                    :label="group.label"
                  >
                    <el-option
                      v-for="(item, index) in group.options"
                      :key="index + '-id'"
                      :label="item.clientName"
                      :value="item.clientId"
                    >
                      <span>{{ item.clientName }} </span>
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('dc:部门')" class required>
                <el-select
                  v-model="formData.divisionId"
                  placeholder="please select product"
                  filterable
                  style="width: 424px"
                >
                  <el-option label="All" :value="0" @click.native="changDivision({ divisionId: 0 })"
                    >All</el-option
                  >
                  <el-option
                    :label="item.divisionName"
                    :value="item.divisionId"
                    v-for="(item, index) in divisionList"
                    :key="index"
                    @click.native="changDivision(item)"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="$t('dc:品牌')" required>
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  :disabled="brandList.length == 0"
                  @change="handleCheckAllChange"
                  >All</el-checkbox
                >
                <div class="checkBox">
                  <el-checkbox-group
                    v-model="formData.brandIdList"
                    @change="handleCheckedCitiesChange"
                  >
                    <el-checkbox
                      :title="item.brandName"
                      v-for="item in brandList"
                      :label="item.brandId"
                      :key="item.brandId"
                      >{{ item.brandName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </el-form>
            <el-form
              label-position="right"
              label-width="90px"
              :inline="false"
              ref="formIndData"
              :model="formIndData"
              :rules="rules"
              v-show="dimensionType === 1"
            >
              <el-form-item :label="$t('dc:纬度')" class required>
                <el-select
                  filterable
                  style="width: 424px"
                  :placeholder="$t('dc:请选择')"
                  v-model="dimensionType"
                  @change="selectDimemsion"
                  ref="dimension2"
                >
                  <el-option
                    v-for="item in dimensionOptionsp"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                    @click.native="selectDimension(item)"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="$t('dc:行业')" required>
                <el-checkbox
                  :indeterminate="isIndustryType"
                  :disabled="industryList.length == 0"
                  v-model="checkIndListAll"
                  @change="handleCheckIndAllChange"
                  >All</el-checkbox
                >
                <div class="checkBox">
                  <el-checkbox-group
                    v-model="formIndData.industryIdList"
                    @change="handleCheckedIndChange"
                  >
                    <el-checkbox
                      v-for="item in industryList"
                      :title="item.industryName"
                      :label="item.industryId"
                      :key="item.industryId"
                      >{{ item.industryName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </el-form-item>
              <!-- <el-form-item :label="$t('dc:品牌')" required>
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkIndAll"
                  :disabled="brandIndList.length == 0"
                  @change="handleCheckAllIndChange"
                  >All</el-checkbox
                >
                <div class="checkBox">
                  <el-checkbox-group
                    v-model="formIndData.brandIdList"
                    @change="handleCheckedIndBrandChange"
                  >
                    <el-checkbox
                      v-for="item in brandIndList"
                      :title="item.brandName"
                      :label="item.brandId"
                      :key="item.brandId"
                      >{{ item.brandName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </el-form-item> -->
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="" @click="show = false">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-black" type="info" @click="submit()">{{ $t('dc:确认') }}</el-button>
      </div>
    </el-dialog>
    <Forbid4Delete
      v-if="showGroupDialog"
      :visible.sync="showGroupDialog"
      :title="groupTitle"
      :content="groupContent"
      @confrom="confrom"
    ></Forbid4Delete>
  </div>
</template>
<script>
import Forbid4Delete from '@/views/dialog/usuallyDialog'
export default {
  name: 'accessAllocation',
  components: {
    Forbid4Delete,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    rawData: Object,
    title: String,
    content: String,
  },

  data() {
    var brandListRoute = (rule, value, callback) => {
      if (value == []) {
        callback(new Error(this.$t('dc:请选择')))
      } else {
        callback()
      }
    }
    var divisonIdRoute = (rule, value, callback) => {
      if (value == '') {
        callback(new Error(this.$t('dc:请选择')))
      } else {
        callback()
      }
    }
    return {
      show: this.visible,
      titleMsg: this.$t('dc:权限分配'),
      rowData: this.rawData,
      productList: [],
      moduleList: [],
      accessList: [],
      tabPaneType: 0,
      loading: false,
      showGroupDialog: false,
      noHistorical: true,
      groupTitle: this.$t('dc:分配'),
      groupContent: this.$t('dc:确认将当前操作访问权限分配给该组吗'),
      clientContent: this.$t('dc:确认切换到数据访问页面并保存当前更改'),
      tabCountent: this.$t('dc:确认切换到数据访问页面并保存当前更改'),
      projectId: '',
      moduleId: '',
      keyword: '',
      tableData: [],
      pageConfig: {
        pageNum: 1,
        pageSize: 10,
        total: 20,
        projectId: '',
        keyword: '',
      },
      accessList: [],
      selectTableList: null,
      activeName: '1',
      product: [],
      checkAll: false,
      checkIndAll: false,
      checkIndListAll: false,
      checkedList: [],
      brandList: [],
      brandIndList: [],
      industryList: [],
      isIndeterminate: false,
      isIndustryType: false,
      dimensionType: 0,
      submitType: 0,
      formData: {
        clientId: '',
        divisionId: 0,
        brandIdList: [],
        // industryIdList:[2,3],
        groupId: this.rawData.userGroupId,
      },
      formIndData: {
        brandIdList: [],
        industryIdList: [],
        groupId: this.rawData.userGroupId,
      },
      formOhterData: {
        brandIdList: [],
        industryIdList: [],
        groupId: this.rawData.userGroupId,
      },
      industryIdList: [2, 3],
      rules: {
        divisionId: [{ validator: divisonIdRoute, trigger: ['change'] }],
        brandIdList: [{ validator: brandListRoute, trigger: ['change'] }],
      },
      clientList: [],
      divisionList: [],
      brandParams: {
        clientId: '',
        divisionId: 0,
        groupId: this.rawData.userGroupId,
      },
      industryParams: {
        groupId: this.rawData.userGroupId,
        industryIdList: [],
      },
      dimensionOptionsp: [
        {
          id: 0,
          label: 'Client',
        },
        {
          id: 1,
          label: 'Industry',
        },
      ],
      options: [
        {
          id: 0,
          label: this.$t('dc:历史记录'),
          options: [],
        },
        {
          id: 1,
          label: this.$t('dc:所有记录'),
          options: [],
        },
      ],
      clickTabStatus: true,
      formName: 'formData',
    }
  },
  computed: {
    columnConfig() {
      return [
        {
          key: 'projectName',
          name: this.$t('dc:产品名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: 60,
        },
        {
          key: 'moudule',
          name: this.$t('dc:模块名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: 140,
        },

        {
          key: 'accessList',
          name: this.$t('dc:权限名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 240,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
    rawData(v) {
      this.rowData = v
    },
    activeName(v) {
      this.clickTabStatus = true
      switch (v) {
        case '1':
          this.groupTitle = this.$t('dc:分配')
          this.groupContent = this.$t('dc:确认将当前操作访问权限分配给该组吗')
          this.tabCountent = this.$t('dc:确认切换到数据访问页面并保存当前更改')
          this.formData = {
            clientId: '',
            divisionId: 0,
            brandIdList: [],
            groupId: this.rawData.userGroupId,
          }
          this.initEvent()
          break
        case '2':
          this.dimensionType = 0
          this.groupTitle = this.$t('dc:提醒')
          this.groupContent = this.$t('dc:确认将当前数据访问权限分配给该组吗')
          this.tabCountent = this.$t('dc:确认切换到操作访问页面并保存当前更改')
          this.getDivisonDataList()
          this.getClentList(this.formData)
          break
        default:
          break
      }
    },
    tableData: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue == oldValue) {
          this.clickTabStatus = false
        }
      },
    },
    formData: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue == oldValue) {
          this.clickTabStatus = false
        }
      },
    },
    'formData.brandIdList': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue == oldValue) {
          this.clickTabStatus = false
        }
      },
    },
    formIndData: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue == oldValue) {
          this.clickTabStatus = false
        }
      },
    },
    dimensionType: {
      deep: true,
      handler(newValue, oldValue) {
        switch (newValue) {
          case 0:
            this.formName = 'formData'
            this.clientContent = this.$t('dc:确认切换到客户端并保存当前更改吗')
            break
          case 1:
            this.formName = 'formIndData'
            this.clientContent = this.$t('dc:确认切换到行业并保存当前更改吗')
            break

          default:
            break
        }
      },
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      this.getProductList()
      this.getAccessInfoData({ userGroupId: this.rowData.userGroupId })
    },
    selectProduct() {
      if (!!this.projectId) this.getModuleList(this.projectId)
      else this.moduleList = []
      this.getAccessTable(this.projectId, this.rowData.userGroupId, this.moduleId)
    },
    selectDimemsion(v) {
      // return
      // if (this.dimensionType == v) this.dimensionType = v
    },
    selectModule() {
      this.getAccessTable(this.projectId, this.rowData.userGroupId, this.keyword)
    },
    //获取用户组详情
    async getAccessInfoData(id) {
      let { status, data } = await $Api.group.getAccessInfo(id)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (data) {
          this.selectTableList = data.moduleList
          this.getAccessTable(this.projectId, this.rowData.userGroupId, this.keyword)
        }
      }
    },
    //获取table数据
    async getAccessTable(id, userGroupId, keyword) {
      let { status, data } = await $Api.role.getAccessProductList(id, userGroupId, keyword)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        let _tableData = data
        _tableData.map((item, index) => {
          item.selected = false
          item.accessNewlist = item.accessList
          item.accessList = []
          if (this.selectTableList) {
            this.selectTableList.map((items, sindex) => {
              if (item.moduleId === items.moduleId) {
                item.selected = true
                items.accessList.map((itemA) => {
                  // item.selected = false;
                  item.accessList.push(itemA.accessId)
                })
              }
            })
          }
        })
        this.tableData = _tableData
        this.pageConfig.total = data.total
      }
    },
    changeShowAccessList(row, index) {
      this.tableData[index].accessList = []
    },
    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.productList = data
      }
    },
    //获取模块列表
    async getModuleList(id) {
      let { status, data } = await $Api.project.getModuleListByProduct(id)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.moduleList = data
      }
    },
    confrom() {
      if (this.activeName === '1') {
        let upParams = {
          userGroupId: this.rowData.userGroupId,
          moduleList: [],
        }
        this.tableData.map((item) => {
          let moduleJson = {
            projectId: item.projectId,
            modelId: item.moduleId,
            accessList: item.accessList,
          }
          upParams.moduleList.push(moduleJson)
        })
        this.UpdateAccess(upParams)
      } else {
        switch (this.dimensionType) {
          case 0:
            this.formData.groupId = this.rowData.userGroupId
            this.submitAccess(this.formData)
            break
          case 1:
            this.formIndData.groupId = this.rowData.userGroupId
            this.formIndData.brandIdList = []
            this.submitIndustry(this.formIndData)
            break

          default:
            break
        }
      }
    },
    //保存修改
    async UpdateAccess(params, type) {
      let { status } = await $Api.group.saveUpdateAccess(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS && this.tabPaneType == 0) {
        this.$message({
          showClose: true,
          message: this.$t('dc:成功') || 'success',
          type: 'success',
        })
        this.showGroupDialog = false
        this.show = false
      }
    },
    // clent下 brand全选存brandId
    handleCheckAllChange(val) {
      this.formData.brandIdList = val ? this.brandList.map((v) => v.brandId) : []
      this.isIndeterminate = false
    },
    // clent下 取消clent的brand全选
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.brandList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.brandList.length
    },
    // Industry下 全选brand存brandIdList
    handleCheckAllIndChange(val) {
      this.formIndData.brandIdList = val ? this.brandIndList.map((v) => v.brandId) : []
      this.isIndeterminate = false
    },
    // Industry下 全选industry
    handleCheckIndAllChange(val) {
      this.formIndData.industryIdList = val ? this.industryList.map((v) => v.industryId) : []
      this.isIndustryType = false
      this.industryParams.industryIdList = this.formIndData.industryIdList
      this.getIndustryBrandData(this.industryParams)
    },
    // Industry下 取消industry的brand全选
    handleCheckedIndBrandChange(value) {
      let checkedCount = value.length
      this.checkIndAll = checkedCount === this.brandIndList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.brandIndList.length
    },
    // Industry下 取消industry的Industry(行业)全选
    handleCheckedIndChange(value) {
      let checkedCount = value.length
      this.checkIndListAll = checkedCount === this.industryList.length
      this.isIndustryType = checkedCount > 0 && checkedCount < this.industryList.length
      this.industryParams.industryIdList = this.formIndData.industryIdList
      this.getIndustryBrandData(this.industryParams)
    },

    // 选择维度
    selectDimension(item) {
      this.submitType = 0
      if (!this.clickTabStatus) {
        this.changeIndustry(item)
        this.$confirm(this.clientContent, this.$t('dc:提醒'), {
          confirmButtonText: this.$t('dc:确认'),
          cancelButtonText: this.$t('dc:取消'),
          type: 'text',
        })
          .then(() => {
            this.changeIndustry(item)
            switch (this.dimensionType) {
              case 1:
                this.submitAccess(this.formData)
                setTimeout(() => {
                  this.checkIndAll = false
                  this.getIndustryData(this.industryParams)
                }, 500)

                break
              case 0:
                this.submitIndustry(this.formIndData)
                setTimeout(() => {
                  this.formData.brandIdList = []
                  this.formData.clientId = ''
                  this.formData.divisionId = 0
                  this.getDivisonDataList()
                  this.getClentList(this.formData)
                }, 500)
                break

              default:
                break
            }

            // this.clickTabStatus = true
            // 你可以在这儿做些操作
          })
          .catch((err) => {
            // this.clickTabStatus = false
            // this.formData.brandIdList = [];
            // this.getDivisonDataList();
            // this.getClentList(this.formData);
            // this.clickTabStatus = true
            // this.changeIndustry()
          })
      } else {
        switch (this.dimensionType) {
          case 0:
            this.formName = 'formData'
            this.formData.brandIdList = []
            this.formData.clientId = ''
            this.formData.divisionId = 0
            this.getDivisonDataList()
            this.getClentList(this.formData)
            break
          case 1:
            this.checkIndAll = false
            this.formName = 'formIndData'
            this.getIndustryData(this.industryParams)
            break

          default:
            break
        }
      }
    },
    // 去反Industry
    changeIndustry(item) {
      if ((this.dimensionType == item.id) == 0) this.dimensionType = 0
      if ((this.dimensionType == item.id) == 1) this.dimensionType = 1
      if (this.dimensionType == 0) {
        this.dimensionType = 1
      } else {
        this.dimensionType = 0
      }
    },
    // 选择clientList()
    changClient(value) {
      this.isIndeterminate = false
      this.checkAll = false
      this.formData.brandIdList = []
      this.getClentList(this.formData)
      // this.getBrandDataList(this.brandParams);
    },
    // 选择division
    changDivision(value) {
      this.formData.divisionId = value.divisionId
      this.isIndeterminate = false
      this.checkAll = false
      this.formData.brandIdList = []
      this.getClentList(this.formData)
      // this.getBrandDataList(this.brandParams);
    },
    changeTabs(activeName, oldActiveName) {
      this.tabPaneType = 1
      this.submitType = 0
      this.formName = 'formData'
      var activeStatus = new Promise((resolve, reject) => {
        if (this.clickTabStatus) {
          resolve()
        } else {
          this.$confirm(this.tabCountent, this.$t('dc:提醒'), {
            confirmButtonText: this.$t('dc:确认'),
            cancelButtonText: this.$t('dc:取消'),
            type: 'text',
          })
            .then(() => {
              // 你可以在这儿做些操作
              this.confrom(this.tabPaneType)
              resolve()
            })
            .catch((err) => {
              // resolve()
              reject(err)
            })
        }
      })
      return activeStatus
    },
    submit() {
      this.submitType = 1
      switch (this.formName) {
        case 'formData':
          this.tabPaneType = 0
          if (this.activeName === '2') {
            if (this.formData.clientId === '' || this.formData.divisionId === '') {
              return this.$message({
                showClose: true,
                message: this.$t('dc:请选择'),
                type: 'warning',
              })
            }
          }

          this.$refs[this.formName].validate(async (valid) => {
            // if (valid) {
            this.showGroupDialog = true
            // } else {
            //   return false;
            // }
          })
          break
        case 'formIndData':
          // if (this.formIndData.industryIdList.length == 0) {
          //   return this.$message({
          //     showClose: true,
          //     message: this.$t('dc:请选择'),
          //     type: 'warning',
          //   })
          // }
          this.$refs[this.formName].validate(async (valid) => {
            //  if (valid) {
            this.showGroupDialog = true
            // } else {
            //   return false;
            // }
          })
          break

        default:
          break
      }
    },
    // 获取clent列表
    async getClentList(params) {
      let { status, data } = await $Api.group.getAccessClientList(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        // this.clientList = data;
        let _formData = {}
        this.clientList = data.clientList
        if (data.historyList.length == 0) {
          this.noHistorical = false
          this.formIndData.brandIdList = []
          this.options[0].options = []
        } else {
          this.noHistorical = true
          this.options[0].options = data.historyList
        }
        this.options[1].options = data.clientList
        if (data.selectedListVO) {
          _formData.clientId = data.selectedListVO.clientVO.clientId
          _formData.divisionId = data.selectedListVO.divisionVO.divisionId
          _formData.brandIdList = data.selectedListVO.selectedBrandList
          this.brandList = data.selectedListVO.brandList
          if (data.selectedListVO.clientVO.clientId == 0) {
            _formData.clientId = ''
          }
          // this.brandIndList = data.selectedListVO.brandList
          if (
            data.selectedListVO.selectedBrandList.length === data.selectedListVO.brandList.length
          ) {
            this.checkAll = true
            this.isIndeterminate = false
          } else {
            this.checkAll = false
            if (data.selectedListVO.selectedBrandList.length == 0) {
              this.isIndeterminate = false
            } else this.isIndeterminate = true
          }
        } else {
          _formData.clientId = ''
          _formData.divisionId = 0
          _formData.brandIdList = []
          this.brandList = []
        }
        _formData.groupId = this.rawData.userGroupId
        _formData.dimensionType = 0
        this.clickTabStatus = true
        this.formData = _formData
      }
    },
    // 获取行业数据及选中数据
    async getIndustryData(params) {
      let { status, data } = await $Api.groupIndustry.getIndustry(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.formOhterData = {
          brandIdList: [],
          industryIdList: [],
          groupId: this.rawData.userGroupId,
        }
        this.industryList = data.industryList
        this.formOhterData.industryIdList = data.selectedIndustryList
        this.industryParams.industryIdList = data.selectedIndustryList
        this.getIndustryBrandData(this.industryParams)
        this.clickTabStatus = true
      }
    },
    async getIndustryBrandData(params) {
      let { status, data } = await $Api.groupIndustry.getIndustryBrand(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.formOhterData.brandIdList = data.selectedBrandList
        this.brandIndList = data.brandList
        this.formIndData = this.formOhterData
        this.clickTabStatus = true
      }
    },
    async getDivisonDataList(params) {
      let { status, data } = await $Api.group.getDivisonList(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.divisionList = data
      }
    },
    async getBrandDataList(params) {
      let { status, data } = await $Api.group.getBrandList(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.brandList = data
      }
    },
    // 保存行业数据
    async submitIndustry(params) {
      this.$refs.dimension1.blur()
      this.$refs.dimension2.blur()
      let { status } = await $Api.groupIndustry.confirmIndustry(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (this.submitType === 1) this.show = false
        this.clickTabStatus = true
        this.$message({
          showClose: true,
          message: this.$t('dc:成功') || 'success',
          type: 'success',
        })
      }
    },
    async submitAccess(params) {
      this.$refs.dimension1.blur()
      this.$refs.dimension2.blur()
      let { status } = await $Api.group.confirmAccess(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS && this.tabPaneType == 0) {
        if (this.submitType === 1) this.show = false
        this.clickTabStatus = true
        this.$message({
          showClose: true,
          message: this.$t('dc:成功') || 'success',
          type: 'success',
        })
      }
    },
    delectClent(value) {
      this.options[0].options.map((item, index) => {
        if (item.clientId === value.clientId) return this.options[0].options.splice(index, 1)
      })
    },
    closeEmail() {},
    pageSizeChange() {},
    pageChange() {},
  },
}
</script>
<style lang="scss">
.access-allocation-dialog {
  .el-dialog .el-dialog__body {
    text-align: left;
  }
  .groupSelect {
    margin-bottom: 10px;
    padding-left: 16px;
    display: flex;
    > div {
      margin-right: 10px;
    }
    label {
      display: block;
      color: black;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 6px;
    }
  }
  .projectName {
    display: inline-block;
    margin-right: 5px;
  }
  .mouduleName {
    margin-left: 5px;
  }
  .elTabtwo {
    margin-left: 10px;
  }
  .elTabtwo .el-checkbox-group {
    max-height: 180px !important;
    overflow-y: scroll;
  }
  .el-switch__core {
    height: 18px;
    line-height: 18px;
  }
  .el-switch.is-checked .el-switch__core::after {
    margin-left: -16px;
  }
  .el-switch__core:after {
    height: 14px;
    width: 14px;
    top: 1px;
  }
  // .checkBox .el-checkbox-group {
  //   display: flex;
  //   flex-wrap: wrap;
  // }
  .checkBox .el-checkbox-group label {
    width: 31%;
  }
  .el-checkbox {
    height: 24px;
    width: 100%;
    overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }
  .el-checkbox .el-checkbox__label {
    // display: inline-block;
    // line-height: 1.1;
    // height: 14px;
    // width: 170px;
    // // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }
  .dataAccess {
    min-height: 220px;
    overflow-y: scroll;
  }
  .elTabtwo .el-checkbox-group::-webkit-scrollbar {
    width: 4px;
  }
  /* 滚动条滑块 */
  .elTabtwo .el-checkbox-group::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  }
  .el-checkbox-group.checkBox {
    display: flex;
    flex-wrap: wrap;
    margin-left: 16px;
  }
}
</style>
